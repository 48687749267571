import * as React from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { Card, Typography } from "@mui/material";
import { textAlign } from "@mui/system";

interface Props {
  scanCount: number;
  border?: number;
  scanDelay: number;
}

export default function KeyFinder(props: Props) {
  const { scanCount, border = 40, scanDelay } = props;

  const [color, setColor] = React.useState("rgba(255, 0, 0, 0.5)");

  React.useEffect(() => {
    setColor("rgba(0, 255, 0, 0.5)");

    let timer = setTimeout(() => {
      setColor("rgba(255, 0, 0, 0.5)");
    }, scanDelay);

    return () => {
      clearTimeout(timer);
    };
  }, [scanCount]);

  return (
    <React.Fragment>
      <svg
        viewBox="0 0 24 24"
        style={{
          top: 0,
          left: 0,
          zIndex: 1,
          boxSizing: "border-box",
          border: `${border}px solid rgba(0, 0, 0, 0.1)`,
          position: "absolute",
          width: "100%",
          height: "100%",
          fill: "none",
          opacity: 0.25,
        }}
      >
        <path
          d="M12.3212 10.6852L4 19L6 21M7 16L9 18M20 7.5C20 9.98528 17.9853 12 15.5 12C13.0147 12 11 9.98528 11 7.5C11 5.01472 13.0147 3 15.5 3C17.9853 3 20 5.01472 20 7.5Z"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </React.Fragment>
  );
}
