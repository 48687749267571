import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";

type Props = {
  url: string;
  alt: string;
};

export default function Footer({ url, alt }: Props) {
  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{ top: "auto", bottom: 0 }}
    >
      <Toolbar>
        <div style={{ textAlign: "center", margin: "0 auto" }}>
          <img src={url} alt={alt} width="40%" />
          <Typography
            variant="caption"
            display="block"
            style={{ marginTop: -2 }}
          >
            &copy; 2023 Microsoft &middot;{" "}
            <a href="https://privacy.microsoft.com/privacystatement">
              Privacy &amp; Cookies
            </a>{" "}
            &middot;{" "}
            <a href="https://www.microsoft.com/trademarks">Trademarks</a>
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
}
