import * as React from "react";
// import InstantVerifier from "./InstantVerifier";
import Header from "./Header";
import Footer from "./Footer";
import Paper from "@mui/material/Paper";
import Verifier from "./Verifier";

export default function App() {
  return (
    <div>
      <Header title="Princeton ElectionGuard Verifier" />
      {/* <Header title="Microsoft ElectionGuard Verifier" /> */}
      <Paper elevation={0}>
        <Verifier />
      </Paper>

      {/* <Footer url="/microsoft.png" alt="Microsoft Corporation" /> */}
      <Footer url="/princeton.svg" alt="Princeton University" />
    </div>
  );
}
