import { Buffer } from "buffer";
// import init, { eg_hash_compute, eg_nonce_selection } from "voter";
import {
  Ballot,
  Config,
  Contest,
  ContestOption,
  ElectionRecordHeader,
  ProofGuardian,
} from "./types";

/* Helpers */

export const fromHexString = (hex: string) => {
  var bytes = [];
  for (var c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substr(c, 2), 16));
  }
  return bytes;
};

export const toHexString = (arr: Uint8Array) =>
  Array.from(arr, (i) => i.toString(16).padStart(2, "0")).join("");

export const str2bytes = (s: string) => Uint8Array.from(Buffer.from(s));

export const base64decode = (s: string) =>
  Uint8Array.from(Buffer.from(s, "base64"));
export const base64encode = (b: Uint8Array) =>
  Buffer.from(b).toString("base64");

export const hexDecode = (s: string) => Uint8Array.from(Buffer.from(s, "hex"));
export const hexEncode = (b: Uint8Array) => Buffer.from(b).toString("hex");

export const hex2base64 = (s: string) => base64encode(hexDecode(s));

export const hash2hex = (h: string) => h.substring(2, h.length - 1);

export const pad_with_zeros = (b: Uint8Array, n: number) =>
  new Uint8Array([...new Uint8Array(n - b.length), ...b]);

export const isValidConfirmationCode = (cc: string) =>
  base64decode(JSON.parse(cc).cc).length === 32;

export const isValidChallengeCode = (cc: string) =>
  base64decode(JSON.parse(cc).pn).length === 32;

/* WebAssembly */

// export function initWebAssembly() {
//   init().then(() => {
//     console.log("Initialized webassembly.");
//   });
// }

/* Election Record */

export const isValidRecord = (record_str: string) => {
  try {
    let valid = true;
    var record: ElectionRecordHeader = JSON.parse(record_str);
    valid = hexDecode(record.public_key).length <= 512 && valid;
    valid = hexDecode(record.hashes.h_b).length <= 32 && valid;
    valid = hexDecode(record.hashes.h_p).length <= 32 && valid;
    valid = hexDecode(record.hashes.h_m).length <= 32 && valid;
    valid = hexDecode(record.hashes.h_e).length <= 32 && valid;
    valid = record.manifest.contests.length > 0 && valid;

    for (let i = 0; i < record.manifest.contests.length; i++) {
      let contest = record.manifest.contests[i];
      valid = contest.label.length > 0 && valid;
      valid = contest.selection_limit > 0 && valid;
      valid = contest.options.length > 0 && valid;
      valid = contest.selection_limit < contest.options.length && valid;
      for (let j = 0; j < contest.options.length; j++) {
        valid = contest.options[j].label.length > 0 && valid;
      }
    }
    return valid;
  } catch (e) {
    return false;
  }
};

/* Hash */

export const computeHashes = (header: ElectionRecordHeader) => {
  let p = hexDecode(header.parameters.fixed_parameters.p.substring(2));
  let q = hexDecode(header.parameters.fixed_parameters.q.substring(2));
  let g = hexDecode(header.parameters.fixed_parameters.g.substring(2));
  let manifest = str2bytes(JSON.stringify(header.manifest));
  let date = str2bytes(header.parameters.varying_parameters.date);
  let info = str2bytes(header.parameters.varying_parameters.info);
  let capital_k_i = pad_with_zeros(hexDecode(header.public_key), 512);

  header.guardian_proofs.forEach((proof) => {
    let ret = new Uint8Array();
    proof["2"].forEach((s: string) => {
      ret = new Uint8Array([...ret, ...pad_with_zeros(hexDecode(s), 512)]);
    });
    capital_k_i = new Uint8Array([...capital_k_i, ...ret]);
  });
  return JSON.parse(
    ""
    // eg_hash_compute(p, q, g, manifest, date, info, capital_k_i)
  );
};

/* Nonce */

// export const selectionNonce = (
//   h_e: string,
//   pn: string,
//   contest: Contest,
//   option: ContestOption
// ) =>
//   eg_nonce_selection(
//     base64decode(h_e),
//     base64decode(pn),
//     str2bytes(contest.label),
//     str2bytes(option.label)
//   );

/* Crypto */
