const WORDLIST = [
  "abandon",
  "able",
  "above",
  "absorb",
  "absurd",
  "access",
  "account",
  "achieve",
  "acoustic",
  "across",
  "action",
  "actress",
  "adapt",
  "addict",
  "adjust",
  "adult",
  "advice",
  "affair",
  "afraid",
  "age",
  "agree",
  "aim",
  "airport",
  "alarm",
  "alcohol",
  "alien",
  "alley",
  "almost",
  "alpha",
  "also",
  "always",
  "amazing",
  "amount",
  "analyst",
  "ancient",
  "angle",
  "animal",
  "announce",
  "another",
  "antenna",
  "anxiety",
  "apart",
  "appear",
  "approve",
  "arch",
  "area",
  "argue",
  "armed",
  "army",
  "arrange",
  "arrive",
  "art",
  "artist",
  "ask",
  "assault",
  "assist",
  "asthma",
  "atom",
  "attend",
  "attract",
  "audit",
  "aunt",
  "auto",
  "average",
  "avoid",
  "aware",
  "awesome",
  "awkward",
  "baby",
  "bacon",
  "bag",
  "balcony",
  "bamboo",
  "banner",
  "barely",
  "barrel",
  "basic",
  "battle",
  "bean",
  "because",
  "beef",
  "begin",
  "behind",
  "below",
  "bench",
  "best",
  "better",
  "beyond",
  "bid",
  "bind",
  "bird",
  "bitter",
  "blade",
  "blanket",
  "bleak",
  "blind",
  "blossom",
  "blue",
  "blush",
  "boat",
  "boil",
  "bone",
  "book",
  "border",
  "borrow",
  "bottom",
  "box",
  "bracket",
  "brand",
  "brave",
  "breeze",
  "bridge",
  "bright",
  "brisk",
  "broken",
  "broom",
  "brown",
  "bubble",
  "budget",
  "build",
  "bulk",
  "bundle",
  "burden",
  "burst",
  "business",
  "butter",
  "buzz",
  "cabin",
  "cactus",
  "cake",
  "calm",
  "camp",
  "canal",
  "candy",
  "canoe",
  "canyon",
  "capital",
  "car",
  "card",
  "carpet",
  "cart",
  "cash",
  "castle",
  "cat",
  "catch",
  "cattle",
  "cause",
  "cave",
  "celery",
  "census",
  "cereal",
  "chair",
  "champion",
  "chaos",
  "charge",
  "chat",
  "check",
  "chef",
  "chest",
  "chief",
  "chimney",
  "choose",
  "chuckle",
  "churn",
  "cinnamon",
  "citizen",
  "civil",
  "clap",
  "claw",
  "clean",
  "clever",
  "client",
  "climb",
  "clip",
  "clog",
  "cloth",
  "clown",
  "clump",
  "clutch",
  "coast",
  "code",
  "coil",
  "collect",
  "column",
  "come",
  "comic",
  "company",
  "conduct",
  "congress",
  "consider",
  "convince",
  "cool",
  "copy",
  "core",
  "correct",
  "cotton",
  "country",
  "course",
  "cover",
  "crack",
  "craft",
  "crane",
  "crater",
  "crazy",
  "credit",
  "crew",
  "crime",
  "critic",
  "cross",
  "crowd",
  "cruel",
  "crumble",
  "crush",
  "crystal",
  "culture",
  "cupboard",
  "current",
  "curve",
  "custom",
  "cycle",
  "damage",
  "dance",
  "daring",
  "daughter",
  "day",
  "debate",
  "decade",
  "decide",
  "decorate",
  "deer",
  "define",
  "degree",
  "deliver",
  "demise",
  "dentist",
  "depart",
  "deposit",
  "deputy",
  "describe",
  "design",
  "despair",
  "detail",
  "develop",
  "devote",
  "dial",
  "diary",
  "diesel",
  "differ",
  "dignity",
  "dinner",
  "direct",
  "disagree",
  "disease",
  "dismiss",
  "display",
  "divert",
  "divorce",
  "doctor",
  "dog",
  "dolphin",
  "donate",
  "donor",
  "dose",
  "dove",
  "dragon",
  "drastic",
  "dream",
  "drift",
  "drink",
  "drive",
  "drum",
  "duck",
  "dune",
  "dust",
  "duty",
  "dynamic",
  "eagle",
  "earn",
  "easily",
  "easy",
  "ecology",
  "edge",
  "educate",
  "egg",
  "either",
  "elder",
  "elegant",
  "elephant",
  "elite",
  "embark",
  "embrace",
  "emotion",
  "empower",
  "enable",
  "end",
  "endorse",
  "energy",
  "engage",
  "enhance",
  "enlist",
  "enrich",
  "ensure",
  "entire",
  "envelope",
  "equal",
  "era",
  "erode",
  "error",
  "escape",
  "essence",
  "eternal",
  "evidence",
  "evoke",
  "exact",
  "excess",
  "excite",
  "excuse",
  "exercise",
  "exhibit",
  "exist",
  "exotic",
  "expect",
  "explain",
  "express",
  "extra",
  "eyebrow",
  "face",
  "fade",
  "faith",
  "false",
  "family",
  "fan",
  "fantasy",
  "fashion",
  "fatal",
  "fatigue",
  "favorite",
  "february",
  "fee",
  "feel",
  "fence",
  "fetch",
  "few",
  "fiction",
  "figure",
  "film",
  "final",
  "fine",
  "finish",
  "firm",
  "fiscal",
  "fit",
  "fix",
  "flame",
  "flat",
  "flee",
  "flip",
  "flock",
  "flower",
  "flush",
  "foam",
  "fog",
  "fold",
  "food",
  "force",
  "forget",
  "fortune",
  "forward",
  "foster",
  "fox",
  "frame",
  "fresh",
  "fringe",
  "front",
  "frown",
  "fruit",
  "fun",
  "furnace",
  "future",
  "gain",
  "gallery",
  "gap",
  "garbage",
  "garlic",
  "gas",
  "gate",
  "gauge",
  "general",
  "genre",
  "genuine",
  "ghost",
  "gift",
  "ginger",
  "girl",
  "glad",
  "glare",
  "glide",
  "globe",
  "glory",
  "glow",
  "goat",
  "gold",
  "goose",
  "gospel",
  "govern",
  "grab",
  "grain",
  "grape",
  "gravity",
  "green",
  "grief",
  "grocery",
  "grow",
  "guard",
  "guide",
  "guitar",
  "gym",
  "hair",
  "hammer",
  "hand",
  "harbor",
  "harsh",
  "hat",
  "hawk",
  "head",
  "heart",
  "hedgehog",
  "hello",
  "help",
  "hero",
  "high",
  "hint",
  "hire",
  "hobby",
  "hold",
  "holiday",
  "home",
  "hood",
  "horn",
  "horse",
  "host",
  "hour",
  "hub",
  "human",
  "humor",
  "hungry",
  "hurdle",
  "hurt",
  "hybrid",
  "icon",
  "identify",
  "ignore",
  "illegal",
  "image",
  "immense",
  "impact",
  "improve",
  "inch",
  "income",
  "index",
  "indoor",
  "infant",
  "inform",
  "inherit",
  "inject",
  "inmate",
  "innocent",
  "inquiry",
  "insect",
  "inspire",
  "intact",
  "into",
  "invite",
  "iron",
  "isolate",
  "item",
  "jacket",
  "jar",
  "jealous",
  "jelly",
  "job",
  "joke",
  "joy",
  "juice",
  "jungle",
  "junk",
  "kangaroo",
  "keep",
  "key",
  "kid",
  "kind",
  "kiss",
  "kitchen",
  "kitten",
  "knee",
  "knock",
  "lab",
  "labor",
  "lady",
  "lamp",
  "laptop",
  "later",
  "laugh",
  "lava",
  "lawn",
  "layer",
  "leader",
  "learn",
  "lecture",
  "leg",
  "legend",
  "lemon",
  "length",
  "leopard",
  "letter",
  "liar",
  "library",
  "life",
  "light",
  "limb",
  "link",
  "liquid",
  "little",
  "lizard",
  "loan",
  "local",
  "logic",
  "long",
  "lottery",
  "lounge",
  "loyal",
  "luggage",
  "lunar",
  "luxury",
  "machine",
  "magic",
  "maid",
  "main",
  "make",
  "man",
  "mandate",
  "mansion",
  "maple",
  "march",
  "marine",
  "marriage",
  "mass",
  "match",
  "math",
  "matter",
  "maze",
  "mean",
  "meat",
  "medal",
  "melody",
  "member",
  "mention",
  "mercy",
  "merit",
  "mesh",
  "metal",
  "middle",
  "milk",
  "mimic",
  "minimum",
  "minute",
  "mirror",
  "miss",
  "mix",
  "mixture",
  "model",
  "mom",
  "monitor",
  "monster",
  "moon",
  "more",
  "mosquito",
  "motion",
  "mountain",
  "move",
  "much",
  "mule",
  "muscle",
  "mushroom",
  "must",
  "myself",
  "myth",
  "name",
  "narrow",
  "nation",
  "near",
  "need",
  "neglect",
  "nephew",
  "nest",
  "network",
  "never",
  "next",
  "night",
  "noise",
  "noodle",
  "north",
  "notable",
  "nothing",
  "novel",
  "nuclear",
  "nurse",
  "oak",
  "object",
  "obscure",
  "obtain",
  "occur",
  "october",
  "off",
  "office",
  "oil",
  "old",
  "olympic",
  "once",
  "onion",
  "only",
  "opera",
  "oppose",
  "orange",
  "orchard",
  "ordinary",
  "orient",
  "orphan",
  "other",
  "outer",
  "outside",
  "oven",
  "own",
  "oxygen",
  "ozone",
  "paddle",
  "pair",
  "palm",
  "panel",
  "panther",
  "parade",
  "park",
  "party",
  "patch",
  "patient",
  "pattern",
  "pave",
  "peace",
  "pear",
  "pelican",
  "penalty",
  "people",
  "perfect",
  "person",
  "phone",
  "phrase",
  "piano",
  "picture",
  "pig",
  "pill",
  "pink",
  "pipe",
  "pitch",
  "place",
  "plastic",
  "play",
  "pledge",
  "plug",
  "poem",
  "point",
  "pole",
  "pond",
  "pool",
  "portion",
  "possible",
  "potato",
  "poverty",
  "power",
  "praise",
  "prefer",
  "present",
  "prevent",
  "pride",
  "print",
  "prison",
  "prize",
  "process",
  "profit",
  "project",
  "proof",
  "prosper",
  "proud",
  "public",
  "pull",
  "pulse",
  "punch",
  "puppy",
  "purity",
  "purse",
  "put",
  "pyramid",
  "quantum",
  "question",
  "quit",
  "quote",
  "raccoon",
  "rack",
  "radio",
  "rain",
  "rally",
  "ranch",
  "range",
  "rare",
  "rather",
  "raw",
  "ready",
  "reason",
  "rebuild",
  "receive",
  "record",
  "reduce",
  "reform",
  "region",
  "regular",
  "relax",
  "relief",
  "remain",
  "remind",
  "render",
  "rent",
  "repair",
  "replace",
  "require",
  "resemble",
  "resource",
  "result",
  "retreat",
  "reunion",
  "review",
  "rhythm",
  "ribbon",
  "rich",
  "ridge",
  "right",
  "ring",
  "ripple",
  "ritual",
  "river",
  "roast",
  "robust",
  "romance",
  "rookie",
  "rose",
  "rough",
  "route",
  "rubber",
  "rug",
  "run",
  "rural",
  "saddle",
  "safe",
  "salad",
  "salon",
  "salute",
  "sample",
  "satisfy",
  "sauce",
  "save",
  "scale",
  "scare",
  "scene",
  "school",
  "scissors",
  "scout",
  "screen",
  "scrub",
  "search",
  "seat",
  "secret",
  "security",
  "seek",
  "select",
  "seminar",
  "sense",
  "series",
  "session",
  "setup",
  "shadow",
  "shallow",
  "shed",
  "sheriff",
  "shift",
  "ship",
  "shock",
  "shoot",
  "short",
  "shove",
  "shrug",
  "shy",
  "sick",
  "siege",
  "sign",
  "silk",
  "silver",
  "simple",
  "sing",
  "sister",
  "six",
  "skate",
  "ski",
  "skin",
  "skull",
  "slam",
  "slender",
  "slide",
  "slim",
  "slot",
  "slush",
  "smart",
  "smoke",
  "snack",
  "snap",
  "snow",
  "soccer",
  "sock",
  "soft",
  "soldier",
  "solution",
  "someone",
  "soon",
  "sort",
  "sound",
  "source",
  "space",
  "spatial",
  "speak",
  "speed",
  "spend",
  "spice",
  "spike",
  "spirit",
  "spoil",
  "spoon",
  "spot",
  "spread",
  "spy",
  "squeeze",
  "stable",
  "staff",
  "stairs",
  "stand",
  "state",
  "steak",
  "stem",
  "stereo",
  "still",
  "stock",
  "stone",
  "story",
  "strategy",
  "strike",
  "struggle",
  "stuff",
  "style",
  "submit",
  "success",
  "sudden",
  "sugar",
  "suit",
  "sun",
  "sunset",
  "supply",
  "sure",
  "surge",
  "surround",
  "suspect",
  "swallow",
  "swap",
  "swear",
  "swift",
  "swing",
  "sword",
  "symptom",
  "system",
  "tackle",
  "tail",
  "talk",
  "tape",
  "task",
  "tattoo",
  "teach",
  "tell",
  "tenant",
  "tent",
  "test",
  "thank",
  "theme",
  "theory",
  "they",
  "this",
  "three",
  "throw",
  "thunder",
  "tide",
  "tilt",
  "time",
  "tip",
  "tissue",
  "toast",
  "today",
  "toe",
  "toilet",
  "tomato",
  "tone",
  "tonight",
  "tooth",
  "topic",
  "torch",
  "tortoise",
  "total",
  "toward",
  "town",
  "track",
  "traffic",
  "train",
  "trap",
  "travel",
  "treat",
  "trend",
  "tribe",
  "trigger",
  "trip",
  "trouble",
  "true",
  "trumpet",
  "truth",
  "tube",
  "tumble",
  "tunnel",
  "turn",
  "twelve",
  "twice",
  "twist",
  "type",
  "ugly",
  "unable",
  "uncle",
  "under",
  "unfair",
  "unhappy",
  "unique",
  "universe",
  "unlock",
  "unusual",
  "update",
  "uphold",
  "upper",
  "urban",
  "usage",
  "used",
  "useless",
  "utility",
  "vacuum",
  "valid",
  "valve",
  "vanish",
  "various",
  "vault",
  "velvet",
  "venture",
  "verb",
  "version",
  "vessel",
  "viable",
  "vicious",
  "video",
  "village",
  "violin",
  "virus",
  "visit",
  "vital",
  "vocal",
  "void",
  "volume",
  "voyage",
  "wagon",
  "walk",
  "walnut",
  "warfare",
  "warrior",
  "wasp",
  "water",
  "way",
  "weapon",
  "weasel",
  "web",
  "weekend",
  "welcome",
  "wet",
  "what",
  "wheel",
  "where",
  "whisper",
  "width",
  "wild",
  "win",
  "wine",
  "wink",
  "winter",
  "wisdom",
  "wish",
  "wolf",
  "wonder",
  "wool",
  "work",
  "worry",
  "wrap",
  "wrestle",
  "write",
  "yard",
  "yellow",
  "young",
  "zebra",
  "zone",
];

const bytes2bits = (bytes) => {
  var bits = new Array(256);
  bits.fill(0);
  for (var i = 0; i < 32; i++) {
    var byte = bytes[i];
    for (var j = 0; j < 8; j++) {
      bits[i * 8 + j] = (byte >> (7 - j)) & 1;
    }
  }
  return bits;
};

const bits2int = (bits) => {
  var value = 0;
  for (var i = 0; i < bits.length; i++) {
    value += bits[i] << (bits.length - i - 1);
  }
  return value;
};

const bit2hex = (bits) => {
  return bits2int(bits).toString(16);
};

const bytes2pretty = (bytes) => {
  if (bytes.length != 32) {
    return "INVALID CODE";
  } else {
    var bits = bytes2bits(bytes);
    var words = [];
    var chunkSize = 32;
    for (var i = 0; i < 256 / chunkSize; i++) {
      var wIdx = bits2int(bits.slice(i * chunkSize, i * chunkSize + 10));
      words.push(WORDLIST[wIdx].toUpperCase());
      words.push(
        bit2hex(
          bits.slice(i * chunkSize + 10, (i + 1) * chunkSize)
        ).toUpperCase()
      );
    }
    return words.join("-");
  }
};

export default bytes2pretty;
