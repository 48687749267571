const readSelKeys = () => {
  var map = new Map();

  map.set(
    "Red,Chocolate,Amazon",
    "a7b058a069b234cb2041b553b8b9d1c8b117b7b584dabcc596d1e029605dc85c"
  );
  map.set(
    "Red,Chocolate,Starbucks",
    "2db51da1dc300ae9463d112674fa585d0519cce94a6adc488dc4778429436531"
  );
  map.set(
    "Red,Strawberry,Amazon",
    "bb1954abaad6f1f3c02de7bdaa894647c7943cf35fe47ea5a08cf62e05b66b29"
  );
  map.set(
    "Red,Strawberry,Starbucks",
    "632396ea004e49e3c0bd91eab95c2c78fc0229c1c593eb106a956c54ee7ab3e5"
  );
  map.set(
    "Red,Vanilla,Amazon",
    "48f722c4c24c30b3c190349c81fed3730f4b09125152e8b1d8b685a42f6fde7a"
  );
  map.set(
    "Red,Vanilla,Starbucks",
    "79c9ac31ed574c4cc795aa454ec2376f985518d1040e267881e7b02e368eabc7"
  );
  map.set(
    "Red,Mint,Amazon",
    "4bd55cfc8f4ef760ea4244ad81305d5c6a15688492f791b5a15e130b9a6f8c3f"
  );
  map.set(
    "Red,Mint,Starbucks",
    "174ff708cba28b0717bd91f76fb9c2f563f60b464ae91b5e0a5d56267f9609c9"
  );
  map.set(
    "Red,Hazelnut,Amazon",
    "8a6981c3a7844432c94467619c88d8b40d573304ffe834f3412ac508d119ee8f"
  );
  map.set(
    "Red,Hazelnut,Starbucks",
    "3eeddd5306add3afbf6748a5b8e4212dc7025bbc945d96a44756766d5ef2415e"
  );
  map.set(
    "Red,Other,Amazon",
    "ea8e69a64368ff303e20a809e8565ef67c18f2dd6c214b544739880c04429b8d"
  );
  map.set(
    "Red,Other,Starbucks",
    "935620682766a99239985ecb2668ab0c0ac6e2641f312eb30374fd3206661554"
  );
  map.set(
    "Yellow,Chocolate,Amazon",
    "3bef188a7003b0b3f6b386d1bbd4ce55a477f9037dc673c5527d7d7ff5b123ba"
  );
  map.set(
    "Yellow,Chocolate,Starbucks",
    "3b0db4b341b655559696e7e4e8589d9a6e6692b84ea94161d6a2c331bcaa6879"
  );
  map.set(
    "Yellow,Strawberry,Amazon",
    "aa5ac1610d45fb676f291babcf93f76c511df420531bb673ece3010824ac285c"
  );
  map.set(
    "Yellow,Strawberry,Starbucks",
    "685392fe4ca24b388b4c3207a8df5e81f30bc0f31b3064b8b650ab570d7b42b9"
  );
  map.set(
    "Yellow,Vanilla,Amazon",
    "374cea2638efae47868cb8b0f06f4f692d7313d7d27d0a85a6e7dd6bc7a03a94"
  );
  map.set(
    "Yellow,Vanilla,Starbucks",
    "b8241a19706d8ee00e85f2d409d4fc11b651d3258e80e89ba92fb106d0dbeea4"
  );
  map.set(
    "Yellow,Mint,Amazon",
    "b1eeca6fd7297dc5e0cb2d4319ded1358ac4682ce87304ca8f782f5224101ff4"
  );
  map.set(
    "Yellow,Mint,Starbucks",
    "22a7787bcdac773d4ee0ca85daccd189b3699bbc68d3e4c1c8db6617bf2b81f2"
  );
  map.set(
    "Yellow,Hazelnut,Amazon",
    "ed2995e2419396b1499e08a64bd5efaf4ea16c8bf42f030f0d8b405eefc48b95"
  );
  map.set(
    "Yellow,Hazelnut,Starbucks",
    "499d9bbbcc9850d1ccb9b2945e56e9564f315c582983d8116305cd79dd6d201a"
  );
  map.set(
    "Yellow,Other,Amazon",
    "afd39657366e9d51ada991c93c4a262b564304f60094d97950484f87ce2af6cc"
  );
  map.set(
    "Yellow,Other,Starbucks",
    "7df1bab403bc765675f7234af44caa435a9efc9a3aac266b8d3a076d7ddd6d58"
  );
  map.set(
    "Blue,Chocolate,Amazon",
    "d0fdc239293413d33f799fe057a20aa880c3043295cb6ee75565cd634f49366d"
  );
  map.set(
    "Blue,Chocolate,Starbucks",
    "cb6310e498a8eff29b56624a3ba71f276559ac402b3a3af5cc3c32a266122d0f"
  );
  map.set(
    "Blue,Strawberry,Amazon",
    "80cfeaf8df567c286c49480f8c997b5d2afc44dcabf5c96dfe97706a591d68cd"
  );
  map.set(
    "Blue,Strawberry,Starbucks",
    "df2c358ee2803ff433db67d55e16d87b418131f3873289828118aabc313a77bc"
  );
  map.set(
    "Blue,Vanilla,Amazon",
    "408c4ac627d4d407f97aa5191a4c57c4f7177eff9512b055c4b56604e366ece7"
  );
  map.set(
    "Blue,Vanilla,Starbucks",
    "2d78767e3700bcde75306ada5ec2ad9aea85a5c09b3e867f2c220755523f5c6b"
  );
  map.set(
    "Blue,Mint,Amazon",
    "2da94399c291ce30c5dcdd06c8220ad08d39f76f247f89b121d2b028539c3d8f"
  );
  map.set(
    "Blue,Mint,Starbucks",
    "3500e8b3dddd4070345b3f1e43253697711b58c4fcd62eca95709b67601c64b2"
  );
  map.set(
    "Blue,Hazelnut,Amazon",
    "35ba2df1a5736399888dc1375d95b743262f6e411f3fd96ad056388810e752c9"
  );
  map.set(
    "Blue,Hazelnut,Starbucks",
    "8569c7fdda3d2700fc33ace3c4241907c462bd76b2e91c0ebf158b0f8e8ebd19"
  );
  map.set(
    "Blue,Other,Amazon",
    "0b569c4818d0dfb95e9872bb691ba68d2b43f375414de309b7a230dd3b1a89c1"
  );
  map.set(
    "Blue,Other,Starbucks",
    "b6d12979013f1701f3b8d1b11decca7301955ed42d684b32310fdc00d46e37f6"
  );
  map.set(
    "Green,Chocolate,Amazon",
    "90076f49bc7bf9d3e7cc87147d34ef6843e700632ba39f0b91f29478a409b2eb"
  );
  map.set(
    "Green,Chocolate,Starbucks",
    "4dfba4c65b61addb81b62479d37ac1986237c26a2e114082d28439ae13739107"
  );
  map.set(
    "Green,Strawberry,Amazon",
    "dfc9f9e3c6e6857f9985081614fac426d8b4fdc7a4346d6a8a61225338ea6c48"
  );
  map.set(
    "Green,Strawberry,Starbucks",
    "911dac51bc48594ff3681b48366ff42c22fe5c36094c6715db759ff6810c082a"
  );
  map.set(
    "Green,Vanilla,Amazon",
    "e323315f3eff9b356710de09efb2d19808ebf0298ec3dcd023df8fb87be88857"
  );
  map.set(
    "Green,Vanilla,Starbucks",
    "50a77de37fe212678c029fed146d55a03917ddf272e140433d150a2526bfd60a"
  );
  map.set(
    "Green,Mint,Amazon",
    "d258da117322d67d80c894d33b9d22b9228fc0fcf68c0a28facc954288d19a35"
  );
  map.set(
    "Green,Mint,Starbucks",
    "c111f0c112184b252d7b8d6eb8e0d1596c3b9ca9fbf78839e3b4201fb9edd8c8"
  );
  map.set(
    "Green,Hazelnut,Amazon",
    "8a264e13a527dbfb10cd8a5aea2f10e03042bdcbed08c36330d8a6d4da32d831"
  );
  map.set(
    "Green,Hazelnut,Starbucks",
    "92356db02baf4bad1fde89e2dbc4be78b01bc98a7c4e03c76287a3c73257ac10"
  );
  map.set(
    "Green,Other,Amazon",
    "c9980b69a780885c7d7c9b8e584bed5b72c938fd6ba638ad8f607420a5b15118"
  );
  map.set(
    "Green,Other,Starbucks",
    "9bec07fd37d6051d1650a0e9f8ab28edc104d52fd2b6cd1031d06e5d60c24595"
  );
  map.set(
    "Pink,Chocolate,Amazon",
    "ea3bde31b70edaf820479c22741d10f01100bbb1d4d0dd634fcec181d8c6f87f"
  );
  map.set(
    "Pink,Chocolate,Starbucks",
    "a7f69270f758f72edd240028071a6987e88dfeeb5911df080e5d1c3ba79be230"
  );
  map.set(
    "Pink,Strawberry,Amazon",
    "84b66943ecd7e79ee559e78128289a6f7880f440eb53b66a8650389fcc3215f4"
  );
  map.set(
    "Pink,Strawberry,Starbucks",
    "437b88cc77641409bef7f93fb7aa63bb5c2e6a7f0876753af6fcdfa685c42e47"
  );
  map.set(
    "Pink,Vanilla,Amazon",
    "e488c20469291e72d8776dae7843187a4a06ef40e05b7c359d66c5f19296c251"
  );
  map.set(
    "Pink,Vanilla,Starbucks",
    "2aba70f6d65bf06bbeb909ff0722f0530db4432d21ca34458eadcf977a3bdffb"
  );
  map.set(
    "Pink,Mint,Amazon",
    "faabb0eea00b2ef9a7503e48f673e0eb7b9e7fd4e61437baf57ebb5e2407acfc"
  );
  map.set(
    "Pink,Mint,Starbucks",
    "3218870faec657136ab916978705e9f724f4971cafbeb23446c6f15621973575"
  );
  map.set(
    "Pink,Hazelnut,Amazon",
    "c0baa57e17e7ce3bde924d7f3b06112fdcc89392107deca7c41f78fddb13391c"
  );
  map.set(
    "Pink,Hazelnut,Starbucks",
    "68240fab311f0ded629c2653f126368fe6f1ee140c32905a69435d425153cf83"
  );
  map.set(
    "Pink,Other,Amazon",
    "2d3d259a7acd860a8971a0a94e4bfe560473db5201487ae922385d4bbb628a90"
  );
  map.set(
    "Pink,Other,Starbucks",
    "98aa25b83f000d27a672e4ab873b7d13dc2c8538f7a139a641b481be375c1a2b"
  );
  map.set(
    "Other,Chocolate,Amazon",
    "fa87b0fd1791114af8b42e2bbfd3d339a4a88d5f7bda8ab3bb3b7de034e254ac"
  );
  map.set(
    "Other,Chocolate,Starbucks",
    "bd29c2c213f689783f447369dd0609acaad44a015bebd35d4f6cfe01597c1061"
  );
  map.set(
    "Other,Strawberry,Amazon",
    "63e921f3c7bf65b216f8dc394195d66208a1074666c541a8ffa4535fd792b6e6"
  );
  map.set(
    "Other,Strawberry,Starbucks",
    "81bfbcd5448aee31854910304bfd7e2db75b7b07a4ff2eaffe2d4991cac5bb40"
  );
  map.set(
    "Other,Vanilla,Amazon",
    "062ca0b893ae143151b3496ee63172b9864383a4016c588d9e9a2c4c336b5393"
  );
  map.set(
    "Other,Vanilla,Starbucks",
    "c22d520695aba086d7198c96e8e563c523a48e7385e3dae827db068247cbc832"
  );
  map.set(
    "Other,Mint,Amazon",
    "2245fa72b8bd111c349264b44d04c26ed1146ac06118ac65dab5ebe7b090f063"
  );
  map.set(
    "Other,Mint,Starbucks",
    "b45fe628b1ef541a5fdcc9cd08fd1544cb75689311054934a1d4671e5391ad19"
  );
  map.set(
    "Other,Hazelnut,Amazon",
    "4e602f262f854c8091bf01b09ff464810d3b5dfe40a471b1262a62036174b07a"
  );
  map.set(
    "Other,Hazelnut,Starbucks",
    "5bd39e8136ad52332c67c07f9c35792ccba1636eaf67fd6a41195a0ed83493de"
  );
  map.set(
    "Other,Other,Amazon",
    "c70b8d14a64ac8871d0bc4901b96df92736f0df14b0bd5308d333897a47e55d3"
  );
  map.set(
    "Other,Other,Starbucks",
    "02ee181016521c7c454e2a8762a7704f2ea21fbdd690e4c42bca04448ce3cc11"
  );

  var inverseMap = new Map();
  map.forEach((value, key) => {
    inverseMap.set(value, key);
  });

  return inverseMap;
};

export default readSelKeys();
