import * as React from "react";
import { Typography, Box, Modal, Paper } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { QrScanner } from "@yudiel/react-qr-scanner";
import LockFinder from "./LockFinder";
import { QRCode } from "./types";
import bytes2pretty from "./constants/words_1024.js";
import selKeys from "./constants/selection_keys.js";
import codes from "./constants/codes.js";
import { toHexString, fromHexString, hexDecode } from "./utils";
import KeyFinder from "./KeyFinder";

const LockIcon = (stroke: string) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
      stroke={stroke}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const UnlockIcon = (stroke: string) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3212 10.6852L4 19L6 21M7 16L9 18M20 7.5C20 9.98528 17.9853 12 15.5 12C13.0147 12 11 9.98528 11 7.5C11 5.01472 13.0147 3 15.5 3C17.9853 3 20 5.01472 20 7.5Z"
      stroke={stroke}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const styleCode = {
  margin: "0 auto",
  marginTop: "2.5vh",
  marginBottom: "2.5vh",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 1,
  p: 1,
  height: "16vh",
  wordBreak: "break-all",
};

const styleCodeInner = {
  display: "flex",
  flexDirection: "row" as "row",
  height: "10vh",
  justifyContent: "start",
};

const styleCodeIcon = {
  height: "100%",
  width: "15%",
  paddingTop: "10 %",
};

const styleVerify = {
  margin: "0 auto",
  marginTop: "2.5vh",
  marginBottom: "2.5vh",
  width: "90vw",
  border: "2px solid #000",
  boxShadow: 1,
  p: 1,
};

const scannerStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
};

export default function Verifier() {
  const [stepCameraIcon, setStepCameraIcon] = React.useState(
    <React.Fragment></React.Fragment>
  );
  const [step1TitleText, setStep1TitleText] = React.useState("Step 1");
  const [step2TitleText, setStep2TitleText] = React.useState("Step 2");

  const [lockIconColor, setLockIconColor] = React.useState("black");
  const [unlockIconColor, setUnlockIconColor] = React.useState("black");

  const [lockHex, setLockHex] = React.useState("");
  const [keyHex, setKeyHex] = React.useState("");
  const [hasCameraAccess, setHasCameraAccess] = React.useState(false);
  const [lockedScannerOn, setLockedScannerOn] = React.useState(false);
  const [unlockingScannerOn, setUnlockingScannerOn] = React.useState(false);
  const [variant, setVariant] = React.useState(0);

  const verifyBtn = (
    <React.Fragment>
      <Typography
        variant="button"
        display="block"
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        Verify
      </Typography>
    </React.Fragment>
  );
  const [verifyContent, setVerifyContent] = React.useState(verifyBtn);

  const handleLockedScannerClose = () => setLockedScannerOn(false);
  const handleUnlockingScannerClose = () => setUnlockingScannerOn(false);

  const [confirmCode, setConfirmCode] = React.useState("Scan a CONFIRMED VOTE");

  const [unlockingKey, setUnlockingKey] = React.useState(
    "Scan corresponding TESTING KEY"
  );

  const cameraAccessCallback = (callback: (arg: boolean) => void) => {
    const constraints = {
      video: { facingMode: "environment" },
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        setStepCameraIcon(<CheckBoxIcon color="success" fontSize="inherit" />);
        setHasCameraAccess(true);
        callback(true);
      })
      .catch((err) => {
        setStepCameraIcon(<ErrorIcon color="error" fontSize="inherit" />);
      });
  };

  const scanLocked = () => {
    if (!hasCameraAccess) {
      cameraAccessCallback(setLockedScannerOn);
    } else {
      setLockedScannerOn(true);
    }
  };

  const onDecodeLocked = (result: string) => {
    const qrCode: QRCode = JSON.parse(result);
    setLockedScannerOn(false);
    var verifyBox = document.getElementById("verify-box");
    if (verifyBox !== null) {
      verifyBox.style.backgroundColor = "white";
      verifyBox.style.borderColor = "black";
    }
    try {
      if (qrCode.hex.length > 64) {
        setLockIconColor("red");
        setStep1TitleText("Step 1");
        setConfirmCode("Please scan a valid CONFIRMED VOTE");
        setLockHex("");
      } else {
        setLockIconColor("green");
        setStep1TitleText("Confirmed Vote");
        setConfirmCode(bytes2pretty(fromHexString(qrCode.hex)));
        setLockHex(qrCode.hex);
        setVerifyContent(verifyBtn);
      }
    } catch (error) {
      setLockIconColor("red");
      setStep1TitleText("Step 1");
      setConfirmCode("Please scan a valid CONFIRMED VOTE");
      setLockHex("");
    }
  };
  const onErrorLocked = (error: Error) => {};

  const scanUnlocking = () => {
    if (!hasCameraAccess) {
      cameraAccessCallback(setUnlockingScannerOn);
    } else {
      setUnlockingScannerOn(true);
    }
  };

  const onDecodeUnlocking = (result: string) => {
    const qrCode: QRCode = JSON.parse(result);
    setUnlockingScannerOn(false);
    var verifyBox = document.getElementById("verify-box");
    if (verifyBox !== null) {
      verifyBox.style.backgroundColor = "white";
      verifyBox.style.borderColor = "black";
    }
    try {
      if (qrCode.hex.length <= 64) {
        setUnlockIconColor("red");
        setStep2TitleText("Step 2");
        setUnlockingKey("Please scan the corresponding TESTING KEY");
        setKeyHex("");
        setVariant(0);
      } else {
        setVariant(hexDecode(qrCode.hex.substring(qrCode.hex.length - 2))[0]);
        setUnlockIconColor("green");
        setStep2TitleText("Testing Key");
        setUnlockingKey(
          bytes2pretty(
            fromHexString(qrCode.hex.substring(0, qrCode.hex.length - 2))
          )
        );
        setKeyHex(qrCode.hex.substring(0, qrCode.hex.length - 2));
        setVerifyContent(verifyBtn);
      }
    } catch (error) {
      setUnlockIconColor("red");
      setStep2TitleText("Step 2");
      setUnlockingKey("Please scan the corresponding TESTING KEY");
      setKeyHex("");
      setVariant(0);
    }
  };
  const onErrorUnlocking = (error: Error) => {};

  const handleVerify = () => {
    var nonceBytes = fromHexString(codes.get(lockHex));
    var unlockingKeyBytes = fromHexString(keyHex);
    var selectionKeyBytes = new Uint8Array(32);
    for (var i = 0; i < 32; i++) {
      selectionKeyBytes[i] = nonceBytes[i] ^ unlockingKeyBytes[i];
    }

    if (variant == 0) {
      setVerifyContent(computeSelection(selectionKeyBytes));
    } else {
      setVerifyContent(decideSelection(selectionKeyBytes));
    }
  };

  const computeSelection = (selectionKeyBytes: Uint8Array) => {
    var verifyBox = document.getElementById("verify-box");

    if (selectionKeyBytes.length == 0) {
      if (verifyBox !== null) {
        verifyBox.style.backgroundColor = "red";
        verifyBox.style.borderColor = "red";
      }
      return (
        <Typography
          variant="button"
          display="block"
          sx={{ fontWeight: "bold", textAlign: "center", color: "white" }}
        >
          Verification Failed (Possible Mismatch)
        </Typography>
      );
    }
    try {
      var selections = selKeys.get(toHexString(selectionKeyBytes)).split(",");
      // .join(", ");
      if (verifyBox !== null) {
        verifyBox.style.backgroundColor = "green";
        verifyBox.style.borderColor = "green";
      }
      return (
        <div>
          {selections.map((selection: string) => (
            <Typography
              variant="h5"
              sx={{ textAlign: "center", color: "white" }}
            >
              {selection.toUpperCase()}
            </Typography>
          ))}
        </div>
      );
    } catch (error) {
      if (verifyBox !== null) {
        verifyBox.style.backgroundColor = "red";
        verifyBox.style.borderColor = "red";
      }
      return (
        <Typography
          variant="button"
          display="block"
          sx={{ fontWeight: "bold", textAlign: "center", color: "white" }}
        >
          Verification Failed (Possible Mismatch)
        </Typography>
      );
    }
  };

  const decideSelection = (selectionKeyBytes: Uint8Array) => {
    var verifyBox = document.getElementById("verify-box");

    if (selectionKeyBytes.length == 0) {
      if (verifyBox !== null) {
        verifyBox.style.backgroundColor = "red";
        verifyBox.style.borderColor = "red";
      }
      return (
        <Typography
          variant="button"
          display="block"
          sx={{ fontWeight: "bold", textAlign: "center", color: "white" }}
        >
          Verification Failed (Possible Mismatch)
        </Typography>
      );
    }
    try {
      var _ = selKeys.get(toHexString(selectionKeyBytes)).split(",").join(", ");
      if (verifyBox !== null) {
        verifyBox.style.backgroundColor = "green";
        verifyBox.style.borderColor = "green";
      }
      return (
        <Typography
          variant="button"
          display="block"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            color: "white",
          }}
        >
          Successfully Verified
        </Typography>
      );
    } catch (error) {
      if (verifyBox !== null) {
        verifyBox.style.backgroundColor = "red";
        verifyBox.style.borderColor = "red";
      }
      return (
        <Typography
          variant="button"
          display="block"
          sx={{ fontWeight: "bold", textAlign: "center", color: "white" }}
        >
          Verification Failed (Possible Mismatch)
        </Typography>
      );
    }
  };

  const renderVerifyButton = () => {
    if (lockHex.length > 0 && keyHex.length > 0) {
      return (
        <Box sx={styleVerify} onClick={handleVerify} id="verify-box">
          {verifyContent}
        </Box>
      );
    } else {
      return (
        <Typography
          variant="button"
          display="block"
          sx={{ textAlign: "center" }}
        >
          Tap each step to begin scanning
        </Typography>
      );
    }
  };

  return (
    <React.Fragment>
      <Modal open={lockedScannerOn} onClose={handleLockedScannerClose}>
        <Paper sx={scannerStyle}>
          <Typography
            variant="button"
            display="block"
            sx={{ textAlign: "center" }}
          >
            SCAN CONFIRMED VOTE
          </Typography>
          <QrScanner
            onDecode={onDecodeLocked}
            onError={onErrorLocked}
            viewFinder={LockFinder}
            scanDelay={500}
          />
        </Paper>
      </Modal>

      <Modal open={unlockingScannerOn} onClose={handleUnlockingScannerClose}>
        <Paper sx={scannerStyle}>
          <Typography
            variant="button"
            display="block"
            sx={{ textAlign: "center" }}
          >
            SCAN TESTING KEY
          </Typography>
          <QrScanner
            onDecode={onDecodeUnlocking}
            onError={onErrorUnlocking}
            viewFinder={KeyFinder}
            scanDelay={500}
          />
        </Paper>
      </Modal>

      <Box sx={styleCode} onClick={scanLocked}>
        <Typography
          variant="button"
          display="block"
          sx={{ fontWeight: "bold" }}
        >
          {step1TitleText}
        </Typography>
        <div style={styleCodeInner}>
          <Typography variant="subtitle2" style={{ width: "100%" }}>
            {confirmCode}
          </Typography>
          <div style={styleCodeIcon}>{LockIcon(lockIconColor)}</div>
        </div>
      </Box>
      <Box sx={styleCode} onClick={scanUnlocking}>
        <Typography
          variant="button"
          display="block"
          sx={{ fontWeight: "bold" }}
        >
          {step2TitleText}
        </Typography>

        <div style={styleCodeInner}>
          <Typography variant="subtitle2" style={{ width: "100%" }}>
            {unlockingKey}
          </Typography>
          <div style={styleCodeIcon}>{UnlockIcon(unlockIconColor)}</div>
        </div>
      </Box>

      {renderVerifyButton()}
    </React.Fragment>
  );
}
