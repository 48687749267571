import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";

type Props = {
  title: string;
};

export default function Header({ title }: Props) {
  return (
    <AppBar position="sticky" color="transparent">
      <Toolbar>
        <div style={{ textAlign: "center", marginTop: 4, paddingTop: 4 }}>
          <img src="/logo.svg" alt="ElectionGuard" width="80%" />
          <Typography variant="h5" sx={{ textAlign: "center", mb: 1 }}>
            {title}
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
}
